import React, { useCallback } from 'react';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { Grid } from '@material-ui/core';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import RSSFeed from '../RSSFeed/RSSFeed';
import TitleContainer from '../TitleContainer';
import EpisodeCard from './EpisodeCard/EpisodeCard';
import SeasonDropdown from '../SeasonDropdown/SeasonDropdown';
import PodcastListenButtons from '../PodcastListenButtons';
import SupportingCast from '../SupportingCast';

const useStyles = makeStyles({
  buttons: {
    maxWidth: '600px',
    marginTop: '33px'
  }
});

const PodcastEpisodes = ({ seasons, rssFeedInstructions, handlePlayEpisode, uid }) => {
  const classes = useStyles();
  const isBook = uid === 'book-like-no-other';
  const { session } = useUserContext();
  const { isPrivilegedMember } = session;

  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const seasonParam = search.get('season');
  const selectedSeason = seasonParam
    ? seasons.find(season => season.uid === seasonParam)
    : seasons[0];
  const currentSeason = selectedSeason || seasons[0] || {};

  const { episodes, listenOnSpotify, listenOnApple, listenOnGoogle } = currentSeason;

  const providers = [listenOnSpotify, listenOnApple, listenOnGoogle];

  const rssLink =
    (isPrivilegedMember && currentSeason?.subscriberRssFeed?.url) ||
    currentSeason?.listenOnRSSFeed?.url;

  const handleNavigateToEpisode = useCallback(
    title => () => {
      const clickedEpisode = episodes && episodes.find(episode => episode.title === title);
      navigate(`/${uid}/${clickedEpisode.uid}`);
    },
    [episodes, uid]
  );

  return (
    <>
      {rssLink && <RSSFeed rssLink={rssLink} rssFeedInstructions={rssFeedInstructions} />}
      {currentSeason?.enableSupportingCast && <SupportingCast />}
      <Grid container direction="row" alignItems="center">
        {seasons.length > 1 && (
          <SeasonDropdown seasons={seasons} selectedSeasonUid={currentSeason?.uid} />
        )}
        {isBook && (
          <div className={classes.buttons}>
            <PodcastListenButtons applyBorder providers={providers} />
          </div>
        )}
      </Grid>

      <TitleContainer marginBottom="200px" orderDirection="column" title="Latest Episodes">
        {episodes &&
          episodes.map(
            ({
              createdAt,
              description,
              duration,
              episode,
              part,
              season,
              title,
              uid: episodeUid
            }) => (
              <EpisodeCard
                key={episodeUid}
                season={season}
                title={title}
                part={part}
                createdAt={createdAt}
                episode={episode}
                duration={duration}
                description={description}
                selectedSeasonUid={currentSeason?.uid}
                handleEpisode={handleNavigateToEpisode}
                handlePlayEpisode={handlePlayEpisode}
              />
            )
          )}
      </TitleContainer>
    </>
  );
};

PodcastEpisodes.propTypes = {
  seasons: PropTypes.arrayOf(
    PropTypes.shape({
      listenOnSpotify: PropTypes.shape({
        url: PropTypes.string,
        id: PropTypes.string
      }),
      listenOnApple: PropTypes.shape({
        url: PropTypes.string,
        id: PropTypes.string
      }),
      listenOnGoogle: PropTypes.shape({
        url: PropTypes.string,
        id: PropTypes.string
      }),
      episodes: PropTypes.arrayOf(
        PropTypes.shape({
          createdAt: PropTypes.instanceOf(Date),
          description: PropTypes.string,
          duration: PropTypes.number,
          episode: PropTypes.number,
          part: PropTypes.number,
          season: PropTypes.number,
          title: PropTypes.string,
          wistiaStreamUrl: PropTypes.string
        })
      )
    })
  ),
  rssFeedInstructions: PropTypes.string.isRequired,
  handlePlayEpisode: PropTypes.func.isRequired,
  uid: PropTypes.string.isRequired
};

PodcastEpisodes.defaultProps = {
  seasons: []
};

export default PodcastEpisodes;
