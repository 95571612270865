import { get } from 'lodash';
import {
  extractCardFromArticle,
  extractCardFromGenericPage,
  extractCardFromParshatPage,
  extractCardFromMaterial,
  extractCardFromPlaylist,
  extractCardFromPodcastEpisode,
  extractCardFromPodcastPlaylist,
  extractSeoData
} from '~utils/unfurl-helpers';

export const extractResourceCards = items =>
  (items || []).map(item => {
    const typeName = get(item, 'resource.type', '');
    let resource = null;
    switch (typeName) {
      case 'playlist': {
        resource = extractCardFromPlaylist(item.resource);
        break;
      }
      case 'podcast_playlist': {
        resource = extractCardFromPodcastPlaylist(item.resource);
        break;
      }
      case 'podcast_episode': {
        resource = extractCardFromPodcastEpisode(item.resource);
        break;
      }
      case 'material': {
        resource = extractCardFromMaterial(item.resource);
        break;
      }
      case 'article': {
        resource = extractCardFromArticle(item.resource);
        break;
      }
      case 'holiday_redesign':
        resource = extractCardFromGenericPage(item.resource);
        break;
      case 'parshat': {
        resource = extractCardFromParshatPage(item.resource);
        break;
      }
      default: {
        break;
      }
    }
    return {
      ...resource,
      title: item?.custom_title || resource?.title,
      description: item?.custom_description?.text || resource?.description
    };
  });

export default prismicData => ({
  topNavigationLinks: get(prismicData, 'prismicBrowsePage.data.pages', []).map(page => ({
    url: `/library/${get(page, 'list_page.document.uid', '')}`,
    title: get(page, 'list_page.document.data.navigation_title', '')
  })),
  title: get(prismicData, 'prismicBrowseListPage.data.title', ''),
  sections: get(prismicData, 'prismicBrowseListPage.data.browse_sections', []).map(section => ({
    uid: get(section, 'browse_section.uid', ''),
    url: `/library/${get(prismicData, 'prismicBrowseListPage.uid', '')}/${get(
      section,
      'browse_section.uid',
      ''
    )}`,
    title: get(section, 'browse_section.document.data.title', ''),
    highlightedResources: extractResourceCards(
      get(section, 'browse_section.document.data.highlighted_resources', [])
    )
  })),
  seo: extractSeoData(prismicData, 'prismicBrowseListPage')
});
